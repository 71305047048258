import settingsConfig from 'app/configs/settingsConfig';

const cognitoServiceConfig = {
  profile: `${settingsConfig.apiURL}/customer/profile`,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  region: process.env.REACT_APP_AWS_REGION,
};

export default cognitoServiceConfig;
