import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EnkSplashScreen from '@enk/core/EnkSplashScreen';
import { showMessage } from 'app/store/enk/messageSlice';
import { getSelectedSalon, logoutUser, setUser } from 'app/store/userSlice';
import cognitoService from './services/cognitoService';

const AuthContext = createContext();

function AuthProvider({ children, roleNames }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const [loadingPermissionList, setLoadingPermissionList] = useState(false);
  const dispatch = useDispatch();
  const selectedSalon = useSelector(getSelectedSalon);

  useEffect(() => {
    cognitoService.on('onAutoLogin', () => {
      dispatch(showMessage({ message: 'Signing in' }));

      /**
       * Sign in and retrieve user data with stored token
       */
      cognitoService
        .getUserProfile()
        .then((user) => {
          success(user, 'Signed in');
        })
        .catch((error) => {
          pass(error.message);
        });
    });

    cognitoService.on('onLogin', (user) => {
      success(user, 'Signed in');
    });

    cognitoService.on('onLogout', () => {
      pass('Signed out');

      dispatch(logoutUser());
    });

    cognitoService.on('onAutoLogout', (message) => {
      pass(message);

      dispatch(logoutUser());
    });

    cognitoService.on('onNoAccessToken', () => {
      pass();
    });

    cognitoService.init();

    function success(user, message) {
      if (message) {
        dispatch(showMessage({ message }));
      }

      Promise.all([
        dispatch(setUser(user)),
        // You can receive data in here before app initialization
      ]).then((values) => {
        setWaitAuthCheck(false);
        setIsAuthenticated(true);
      });
    }

    function pass(message) {
      if (message) {
        dispatch(showMessage({ message }));
      }

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }
  }, [dispatch]);

  const getIsAuthenticated = useMemo(() => ({ isAuthenticated }), [isAuthenticated]);

  return waitAuthCheck || loadingPermissionList ? (
    <EnkSplashScreen />
  ) : (
    <AuthContext.Provider value={getIsAuthenticated}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
