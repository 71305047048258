import { authRoles } from 'src/app/auth';
import SignOutPage from './SignOutPage';
import CognitoService from '../../auth/services/cognitoService/cognitoService';

const SignOutConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: CognitoService.isAuthTokenValid(CognitoService.getAccessToken())
    ? null
    : authRoles.restricted,
  routes: [
    {
      path: 'sign-out',
      element: <SignOutPage />,
    },
  ],
};

export default SignOutConfig;
