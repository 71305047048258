import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import settingsConfig from 'app/configs/settingsConfig';
import axios from 'axios';
import { cloneDeep, findIndex } from 'lodash';

export const getSalonWebsiteTemplate = createAsyncThunk(
  'salon/getSalonWebsiteTemplate',
  async (options) => {
    const response = await axios.post(`${settingsConfig.apiURL}/salon/website/template`, options);
    if (!response.data.success) {
      throw new Error('Failed to get salon data');
    }
    return {
      id: options.salon_id,
      calendar_schedules: response.data.calendar_schedules,
      calendar_template: response.data.calendar_template,
      settings: response.data.settings,
      salon: response.data.salon,
    };
  }
);

export const getSalonWebsiteServices = createAsyncThunk(
  'salon/getSalonWebsiteServices',
  async (options) => {
    const response = await axios.post(`${settingsConfig.apiURL}/salon/website/services`, options);
    if (!response.data.success || !response.data.items) {
      throw new Error('Failed to get salon services');
    }
    return {
      id: options.salon_id,
      services: response.data.items,
      options,
    };
  }
);

export const getSalonWebsiteEmployees = createAsyncThunk(
  'salon/getSalonWebsiteEmployees',
  async (options) => {
    const response = await axios.post(`${settingsConfig.apiURL}/salon/website/employees`, options);
    if (!response.data.success || !response.data.items) {
      throw new Error('Failed to get salon employees');
    }
    return {
      id: options.salon_id,
      employees: response.data.items,
      options,
    };
  }
);

export const searchSalonAddresses = createAsyncThunk(
  'salon/searchSalonAddresses',
  async (options) => {
    const response = await axios.post(`${settingsConfig.apiURL}/salon/address/list`, options);
    if (!response.data.success || !response.data.items) {
      throw new Error('Failed to get addresses');
    }
    return response.data;
  }
);

export const searchSalonList = createAsyncThunk('salon/searchSalonList', async (options) => {
  const response = await axios.post(`${settingsConfig.apiURL}/salon/search`, options);
  if (!response.data.success || !response.data.items) {
    throw new Error('Failed to get salons');
  }
  return response.data;
});

export const getServiceSlots = createAsyncThunk('salon/getServiceSlots', async (options) => {
  const response = await axios.post(
    `${settingsConfig.apiURL}/booking/salon/${options.salon_id}/schedule/slots`,
    options,
    {
      headers: {
        'X-timezone': options.timezone || 'UTC',
      },
    }
  );
  if (!response.data.success || !response.data.items) {
    throw new Error('Failed to get slot list');
  }
  return { items: response.data.items, options };
});

export const getProductsInServices = createAsyncThunk(
  'salon/getProductsInServices',
  async (options) => {
    const response = await axios.post(`${settingsConfig.apiURL}/salon/website/products`, options);
    if (!response.data.success || !response.data.items) {
      throw new Error('Failed to get product list');
    }
    return { items: response.data.items, options };
  }
);

export const createOnlineBooking = createAsyncThunk(
  'salon/createOnlineBooking',
  async (options) => {
    const response = await axios.post(
      `${settingsConfig.apiURL}/booking/salon/${options.salon_id}/online/create`,
      options
    );
    if (!response.data.success || !response.data.item) {
      throw new Error('Failed to create booking');
    }
    return response.data;
  }
);

export const createGuestOnlineBooking = createAsyncThunk(
  'salon/createGuestOnlineBooking',
  async (options) => {
    const response = await axios.post(
      `${settingsConfig.apiURL}/booking/salon/${options.salon_id}/online/create/guest`,
      options
    );
    if (!response.data.success || !response.data.item) {
      throw new Error('Failed to create booking');
    }
    return response.data;
  }
);

const salonAdapter = createEntityAdapter({});

export const { selectAll: selectSalonList, selectById: selectSalonById } =
  salonAdapter.getSelectors((state) => state.salon);

const salonSlice = createSlice({
  name: 'salon',

  initialState: salonAdapter.getInitialState({
    searchText: '',
    totalItems: 0,
    addresses: [],
    salons: [],
    /**
     * [{ service_id, salon_id, date, slots: [] }]
     */
    serviceSlots: [],
    serviceEmployees: {},
    employeeServices: {},
  }),

  reducers: {
    setSalonSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getSalonWebsiteTemplate.fulfilled, (state, action) =>
      salonAdapter.upsertOne(state, cloneDeep(action.payload))
    );

    builder.addCase(getSalonWebsiteServices.fulfilled, (state, action) => {
      salonAdapter.upsertOne(
        state,
        cloneDeep({ id: action.payload.id, services: action.payload.services })
      );
      if (action.payload.options.employee_id) {
        state.employeeServices[action.payload.options.employee_id] = cloneDeep(
          action.payload.services
        );
      }
    });

    builder.addCase(getSalonWebsiteEmployees.fulfilled, (state, action) => {
      salonAdapter.upsertOne(
        state,
        cloneDeep({ id: action.payload.id, employees: action.payload.employees })
      );
      if (action.payload.options.service_id) {
        state.serviceEmployees[action.payload.options.service_id] = cloneDeep(
          action.payload.employees
        );
      }
    });

    builder.addCase(searchSalonAddresses.fulfilled, (state, action) => {
      state.addresses = cloneDeep(action.payload.items);
    });

    builder.addCase(searchSalonList.fulfilled, (state, action) => {
      state.salons = cloneDeep(action.payload.items);
    });

    builder.addCase(getServiceSlots.fulfilled, (state, action) => {
      const idx = findIndex(state.serviceSlots, {
        date: action.payload.options.date,
        salon_id: action.payload.options.salon_id,
        service_id: action.payload.options.service_id,
      });

      if (idx === -1) {
        const d = {
          date: action.payload.options.date,
          salon_id: action.payload.options.salon_id,
          service_id: action.payload.options.service_id,
          slots: cloneDeep(action.payload.items),
        };
        state.serviceSlots = state.serviceSlots.concat(cloneDeep([d]));
      } else {
        state.serviceSlots[idx].slots = cloneDeep(action.payload.items);
      }
    });
  },
});

export const { setSalonSearchText } = salonSlice.actions;

export const selectSalonSearchText = ({ salon }) => salon.searchText;
export const selectTotalSalonHistory = ({ salon }) => salon.totalItems;
export const selectSalonAddressList = ({ salon }) => salon.addresses;
export const selectSalonSearchList = ({ salon }) => salon.salons;
export const selectServiceSlotList = ({ salon }) => salon.serviceSlots;
export const selectServiceEmployees = ({ salon }) => salon.serviceEmployees;
export const selectEmployeeServices = ({ salon }) => salon.employeeServices;

export default salonSlice.reducer;
