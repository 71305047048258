import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectEnkCurrentLayoutConfig, selectToolbarTheme } from 'app/store/enk/settingsSlice';
import Logo from 'app/theme-layouts/shared-components/Logo';
import { Link } from 'react-router-dom';
import { selectUser } from 'app/store/userSlice';
import { Button } from '@mui/material';
import UserMenu from '../../shared-components/UserMenu';

function ToolbarLayout2(props) {
  const config = useSelector(selectEnkCurrentLayoutConfig);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const user = useSelector(selectUser);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="enk-toolbar"
        className={clsx('flex relative z-20 shadow-md', props.className)}
        color="default"
        style={{ backgroundColor: toolbarTheme.palette.background.paper }}
      >
        <Toolbar className="container p-0 lg:px-24 min-h-48 md:min-h-64 !max-w-[1200px]">
          <div className="flex shrink-0 items-center px-8">
            <Link to="/">
              <Logo mode="light" />
            </Link>
          </div>

          <div className="flex items-center px-8 h-full overflow-x-auto flex-1 justify-end">
            {user?.data?.id ? (
              <UserMenu />
            ) : (
              <>
                <Link to="/sign-up">
                  <Button variant="contained" color="secondary" className="rounded-4 mr-16">
                    Sign Up
                  </Button>
                </Link>
                <Link to="/sign-in">
                  <Button variant="contained" color="secondary" className="rounded-4">
                    Sign In
                  </Button>
                </Link>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout2);
