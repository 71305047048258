import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import settingsConfig from 'app/configs/settingsConfig';

export const getPresignedUrlsFromFileUpload = createAsyncThunk(
  'storageModule/getPresignedUrlsFromFileUpload',
  async (payload) => {
    const response = await axios.post(
      `${settingsConfig.apiURL}/customer/${payload.customer_id}/upload`,
      payload
    );
    if (!response.data.success) {
      throw new Error('Failed to upload files');
    }
    return { items: response.data.items, errors: response.data.errors };
  }
);
export const uploadFiles = createAsyncThunk(
  'storageModule/uploadFiles',
  ({ files = [], category = 'profile', customerId }, { dispatch }) => {
    if (files.length > 0) {
      const uploadables = files.filter((file) => typeof file !== 'string');
      const urlPayload = {
        customer_id: customerId,
        files: uploadables.map((file) => ({
          category,
          name: file.name,
        })),
      };
      if (uploadables.length > 0) {
        return dispatch(getPresignedUrlsFromFileUpload(urlPayload)).then(({ error, payload }) => {
          if (error) {
            throw new Error(error.message || 'Error uploading file');
          } else {
            return Promise.all(
              payload.items.map(async (item, index) => {
                const formData = new FormData();
                Object.keys(item.fields).forEach((key) => {
                  formData.append(key, item.fields[key]);
                });
                formData.append('Content-Type', uploadables[index].type);
                formData.append('file', uploadables[index]);
                return axios
                  .post(item.url, formData, {
                    headers: {
                      'Content-Type': 'mulmultipart/form-data',
                      Authorization: undefined,
                    },
                  })
                  .then(() => `customer/${customerId}/profile/${uploadables[index].name}`)
                  .catch(() => {
                    throw new Error('Error uploading file');
                  });
              })
            )
              .then((urls) => urls)
              .catch(() => {
                throw new Error('Error uploading file');
              });
          }
        });
      }
      return [];
    }
    return [];
  }
);

const initialState = {};

const storageSlice = createSlice({
  name: 'storageModule',
  initialState,
});

export default storageSlice.reducer;
