import { Amplify } from 'aws-amplify';

const initializeAmplify = (userPoolId, clientId, region) => {
  Amplify.configure({
    Auth: {
      Cognito: {
        region,
        userPoolId,
        userPoolClientId: clientId,
      },
    },
  });
};

export default initializeAmplify;
