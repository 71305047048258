/* eslint-disable class-methods-use-this */
import EnkUtils from '@enk/utils/EnkUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { signIn, confirmSignIn, fetchAuthSession, signOut } from 'aws-amplify/auth';
import { resetSessionRedirectUrl } from '@enk/core/EnkAuthorization/sessionRedirectUrl';
import { get } from 'lodash';
import cognitoServiceConfig from './cognitoServiceConfig';
import initializeAmplify from './amplifyConfig';

class CognitoService extends EnkUtils.EventEmitter {
  init() {
    initializeAmplify(
      cognitoServiceConfig.userPoolId,
      cognitoServiceConfig.clientId,
      cognitoServiceConfig.region
    );
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response &&
            // err.response.status === 401 &&
            (err.response.status === 401 || err.response.status === 403) &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.logout();
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = async () => {
    try {
      const session = await fetchAuthSession();
      const idToken = get(session, 'tokens.idToken', '');
      if (!idToken) {
        this.emit('onNoAccessToken');
        return;
      }
      this.setSession(idToken.toString());
      this.emit('onAutoLogin', true);
    } catch (error) {
      this.logout();
    }
  };

  initSignIn = async (options) => {
    const response = await signIn(options);
    const { isSignedIn, nextStep } = response;
    if (isSignedIn && nextStep && nextStep.signInStep && nextStep.signInStep === 'DONE') {
      const session = await fetchAuthSession();
      const idToken = get(session, 'tokens.idToken', '');
      this.setSession(idToken.toString());
      const user = await this.getUserProfile();
      this.emit('onLogin', user);
    } else if (nextStep && nextStep.signInStep) {
      return response;
    }
    return { isSignedIn: false };
  };

  finishResetNewPassword = async (password) => {
    return confirmSignIn({
      challengeResponse: password,
      options: {
        challengeName: 'NEW_PASSWORD_REQUIRED',
      },
    });
  };

  completeSignIn = async () => {
    const session = await fetchAuthSession();
    const token = get(session, 'tokens.idToken', '');
    if (token) {
      this.setSession(token.toString());
      const user = await this.getUserProfile();
      this.emit('onLogin', user);
    }
  };

  getUserProfile = () => {
    return axios
      .get(cognitoServiceConfig.profile)
      .then((response) => this.transformUserProfileData(response.data))
      .catch((error) => {
        throw error;
      });
  };

  transformUserProfileData = (user) => {
    const result = {
      data: {
        ...user,
        photoURL: 'assets/images/avatars/avatar.jpg',
        shortcuts: ['dashboard', 'spas'],
      },
    };

    if (user.first_name) result.data.displayName = user.first_name;
    if (user.last_name) result.data.displayName += ` ${user.last_name}`;

    return result;
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('jwt_access_token', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;

      resetSessionRedirectUrl();
    }
  };

  logout = async () => {
    this.setSession(null);
    await signOut();
    this.emit('onLogout', 'Logged out');
  };

  isAuthTokenValid = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new CognitoService();

export default instance;
